import { gsap, ScrollTrigger } from 'gsap/all'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

gsap.registerPlugin(ScrollTrigger)

class Nav {
  constructor(nav){
    this.nav = nav
    this.menu = this.nav.querySelector('.menu')
    this.button = this.nav.querySelector('.menu-button')
    this.items = document.querySelectorAll('.navigation .menu-item-wrapper.with-sub')
  }

  init = () => {
    this.button.addEventListener('click', this.openMenu)
    this.items.forEach(el => {
      el.onmouseenter = this.openSub
      el.onmouseleave = this.closeSub
      el.querySelector('.main-menu-item:not(.sub-item)').onclick = this.toggleSub
    })
    window.addEventListener('resize', this.handleResize)

    // this.menuHide()
  }

  openSub = (e) => {
    if(window.matchMedia('(min-width: 1024px)').matches){
      const current = e.currentTarget
      const sub = current.querySelector('.sub-menu')
  
      gsap.to(sub, {
        height: 'auto',
        ease: 'power2.out',
        duration: 0.3
      })
    }
  }

  close = () => this.closeMenu()

  closeSub = (e) => {
    if(window.matchMedia('(min-width: 1024px)').matches){
      const current = e.currentTarget
      const sub = current.querySelector('.sub-menu')
  
      gsap.to(sub, {
        height: 0,
        ease: 'power2.in',
        duration: 0.3
      })
    }
  }

  toggleSub = (e) => {
    if(window.matchMedia('(max-width: 1023px)').matches){
      e.preventDefault()
      e.stopPropagation()
      
      const current = e.currentTarget
      const sub = current.parentElement.querySelector('.sub-menu')

      current.classList.toggle('open')
      gsap.to(sub, {
        height: current.classList.contains('open') ? 'auto' : 0,
        ease: 'power2',
        duration: 0.3,
      })
      this.nav.querySelectorAll('.sub-menu').forEach(subMenu => {
        if(subMenu !== sub){
          subMenu.previousElementSibling.classList.remove('open')
          
          gsap.to(subMenu, {
            height: 0,
            ease: 'power2.in',
            duration: 0.3
          })
        }
      })
    }
  }

  menuHide = () => {
    ScrollTrigger.create({
      trigger: document.body,
      start: '72px top',
      end: 99999,
      onUpdate: ({direction, isActive}) => {
        if(direction == 1){
          this.nav.classList.add('-translate-y-full')
        }else{
          this.nav.classList.remove('-translate-y-full')
        }

        this.closeMenu()
      }
    })
  }

  openMenu = () => {
    console.log('open')
    if(!window.matchMedia('(min-width: 1024px)').matches){
      this.button.classList.toggle('open')
      this.menu.classList.toggle('translate-x-full')

      if (this.button.classList.contains('open')) {
        disablePageScroll()
      }else{
        enablePageScroll()
      }
    }
  }

  closeMenu = () => {
    enablePageScroll()
    if(!window.matchMedia('(min-width: 1024px)').matches){
      this.button.classList.remove('open')
      this.menu.classList.add('translate-x-full')
    }
  }

  handleResize = () => {
    enablePageScroll()
    ScrollTrigger.refresh(true)
    this.closeMenu()
  }

  changeActive = (el) => {
    this.items.forEach(item => item.classList.remove('text-pumpkin-orange'))
    el.classList.add('text-pumpkin-orange')
  }

  setActiveGlobal = () => {
    const path = window.location.pathname
    const currentLink = this.menu.querySelector(`a.main-menu-item[href*="${path}"]`)

    this.menu.querySelectorAll(`.menu-item-wrapper`).forEach(wrapper => {
      wrapper.classList.remove('active')
      this.menu.querySelectorAll('.sub-item').forEach(link => {
        link.classList.remove('text-pumpkin-orange', 'lg:text-cool-grey', 'lg:bg-dark-grey')
        link.classList.add('text-white', 'text-opacity-60', 'lg:text-opacity-100')
      })
    })
    
    if(currentLink && path !== '/'){
      if(currentLink.classList.contains('sub-item')){
        currentLink.classList.add('text-pumpkin-orange', 'lg:text-cool-grey', 'lg:bg-dark-grey')
        currentLink.classList.remove('text-white', 'text-opacity-60', 'lg:text-opacity-100')

        currentLink.parentElement.parentElement.classList.add('active')
      }else{
        currentLink.parentElement.classList.add('active')
      }
    }
  }
}

export default Nav
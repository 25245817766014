import '../css/app.pcss'
import router from './router'
import Nav from './components/nav'
import Swup from './components/Swup'
import { gsap } from 'gsap/all'

if (navigator.appVersion.indexOf("Win")!=-1) document.body.classList.add('windows')

window.addEventListener('load', async () => {
  const currentRoute = await router()
  const menu = new Nav(document.querySelector('.navigation'))
  menu.init()

  Swup(menu, currentRoute)

  gsap.to('.preloader', {
    opacity: 0,
    duration: 0.6,
    delay: 0.2,
    ease: 'power2.out',
    onComplete: () => document.querySelector('.preloader').remove()
  })
})
